
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class StudentOpenPdf extends Vue {
  private created() {
    const url = sessionStorage.getItem('url') || ''
    if (url) {
      const [type, subjectCode, grade, kind] = url.split('/')
      const a = document.createElement('a')
      a.href = `${process.env?.VUE_APP_WEB_PDF_URL}/${type}/subjects/${subjectCode}/grades/${grade}/${kind}/#page=1`
      a.click()
      a.remove()
    } else {
      alert('Something Error')
    }
  }
}
